/**
 * LocaleSwitcher provides clickable elements for changing the locale.
 */
import React from 'react';
import { navigate } from 'gatsby';
import { changePath } from '../util/localizer';
import locales from '../../content/locales';

export default function LocaleSwitcher(props) {
  const rootStyle = (props.rootStyle ? `locale-switcher ${props.rootStyle}` : 'locale-switcher');
  const isMultiLingual = Object.keys(locales).length>1;

  function changeLangHandler(e, targetLang) {
    e.preventDefault();
    // Navigate to locale-specific path
    const newpath = changePath(targetLang, props.currentpath, props.ctx);
    if (newpath) navigate(newpath);
  }

  if (isMultiLingual)
    return (
      <div className={rootStyle}>
        {Object.keys(locales).map((lang, index) => (
          <button key={lang} className="locale-button" onClick={(e) => changeLangHandler(e, lang)}>{lang}</button>
        ))}
      </div>
    );
  return null;
};
